.react-dual-listbox input.rdl-filter{
    border: none;
    border-radius: 0.5rem;
}

.react-dual-listbox select.rdl-control{
    border: none;
    border-radius: 0.5rem;
}

.react-dual-listbox select.rdl-control option {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.react-dual-listbox select.rdl-control option:hover {
    background: rgb(249 43 49 / 0.75);
    color: #FFFFFF;
}

.react-dual-listbox .rdl-actions button{
    border: none;
}
